import './Header.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Logo from '../../images/ombrea.inline.svg';
import Mail from '../../images/mail.inline.svg';
import Burger from '../../images/burger.inline.svg';
import Cross from '../../images/cross.inline.svg';
import { head, sortBy } from 'lodash';
import { property } from 'lodash/fp';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { filterLocaleItems } from '@/helpers/filterNodeByLocale';
import MenuMobile from '@/components/MenuMobile/MenuMobile';

const Header = ({ withCover, url, locale, activeLink }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [toggleHeader, setToggleHeader] = useState(true);
	const [scrollTop, setScrollTop] = useState(0);

	const data = useStaticQuery(
		graphql`
			query HeaderQuery {
				strapiEnglishswitches {
					id
					showEnglish
				}
				pages: allStrapiPages(sort: { fields: orderInHeader }, filter: { showInHeader: { eq: true } }) {
					nodes {
						url {
							url
							label
							locales {
								locale
								localeName
							}
						}
					}
				}
			}
		`
	);

	const showEnglish = property('strapiEnglishswitches.showEnglish')(data);

	useEffect(() => {
		const handleScroll = e => {
			const newScrollTop = e.target.scrollingElement.scrollTop;
			if (newScrollTop > 40) {
				newScrollTop < scrollTop ? setToggleHeader(true) : setToggleHeader(false);
			}
			setScrollTop(newScrollTop);
		};
		if (typeof window !== 'undefined') window.addEventListener('scroll', handleScroll);
		return () => typeof window !== 'undefined' && window.removeEventListener('scroll', handleScroll);
	}, [scrollTop]);

	const homeLink = filterLocaleItems(locale)(head(data.pages.nodes).url);
	const linkList = useMemo(
		() =>
			data.pages.nodes.map(({ url }, i) => {
				const pageUrl = filterLocaleItems(locale)(url);
				return (
					<li key={i} className={activeLink === property('url')(pageUrl) ? 'active-link' : ''}>
						<Link to={property('url')(pageUrl)}>{property('label')(pageUrl)}</Link>
					</li>
				);
			}),
		[activeLink, data.pages.nodes, locale]
	);

	return (
		<>
			<header
				className={cn('header', {
					'has-cover': withCover,
					'show-header': toggleHeader,
					'is-transparent': scrollTop < 90
				})}>
				<ContentWrapper className="header-content">
					<Link to={homeLink.url} className="logo">
						<Logo width={50} height={50} />
						<h1>ombrea</h1>
					</Link>
					<nav>
						{isMenuOpen ? (
							<Cross fill="#999" onClick={() => setIsMenuOpen(false)} className="burger-menu" width={30} height={30} />
						) : (
							<Burger onClick={() => setIsMenuOpen(true)} className="burger-menu" width={30} height={30} />
						)}

						<ul>
							{linkList}
							<li className="we-hire">
								<a
									href={`https://www.welcometothejungle.com/${locale}/companies/ombrea`}
									rel="noopener noreferrer"
									target="_blank">
									{locale === 'fr' ? 'On recrute !' : "We're hiring!"}
								</a>
							</li>
							<li className="contact-link">
								<a href="#contact">
									<Mail width={20} height={20} />
								</a>
							</li>
							{showEnglish &&
								sortBy(url, ({ locales }) => locales.locale).map(({ url, locales }, i) => (
									<li key={i}>
										<Link to={url}>{locales.locale}</Link>
									</li>
								))}
						</ul>
					</nav>
				</ContentWrapper>
			</header>
			{isMenuOpen && <MenuMobile locale={locale} linkList={linkList} />}
			<ContentWrapper>
				<Link to={homeLink.url} className="logo-round">
					<Logo width={50} height={50} />
				</Link>
			</ContentWrapper>
		</>
	);
};

export default Header;
